<template>
    <div class="project-member-manage-ctn">
        <div class="project-member-manage-top">
            <div class="project-member-manage-title">
               <span v-if="title"> {{ title }} |  </span>  项目组成员
            </div>
            <div class="service-charge-btn">
                <a class="service-charge-btn" :href="getServiceChargeRecordUrl" target="_blank">
                    劳务费发放记录
                </a>
            </div>
        </div>
        <div class="table-ctn">
            <a-table rowKey="userId" :columns="columns" :data-source="memberList" :pagination="false" size="small"
                class="exper-table" :scroll="{ x: 1100 }" :loading="loading">
                <template slot="index" slot-scope="text, record, index">
                    {{ index + 1 }}
                </template>
                <template slot="hasAssistantHistory" slot-scope="text, record, index">
                    <div v-if="record.hasAssistantHistory === 2">
                        暂无
                    </div>
                    <a v-else @click="handleCheckAssitantHistoryClick(record)">
                        查看
                    </a>
                </template>
                <template slot="memberType" slot-scope="text, record, index">
                    {{ getProjectMemberTypes(record.memberType) }}
                </template>
                <template slot="operation" slot-scope="text, record, index">
                    <div class="operations">
                        <a v-if="!getIsAssistant(record.memberType) && record.hasRole === 1"
                            @click="handleSetAssistantButtonClick(record)">
                            设为项目助理
                        </a>
                        <a v-if="getIsAssistant(record.memberType) && record.hasRole === 1"
                            @click="handleCancelAssistant(record)">
                            取消助理身份
                        </a>
                        <a v-if="getIsAssistant(record.memberType)" @click="handleAddLaborCostClick(record)">
                            添加劳务费
                        </a>
                        <a v-if="getIsAssistant(record.memberType)" @click="handleAccountInfoClick(record)">
                            账户信息
                        </a>
                        <a v-if="getIsNomalMember(record.memberType)" @click="handleKickOut(record)" style="color: #ff4d4f;">
                            移出项目
                        </a>
                    </div>
                </template>
            </a-table>
        </div>
        <SetProjectAssistantModal :visible.sync="setAssistantModalVisible" :name="selectedAssistantUser.name"
            :phone="selectedAssistantUser.phone" :projectId="selectedAssistantUser.projectId"
            :userId="selectedAssistantUser.userId" @onSetAssistant="onSetAssistant"></SetProjectAssistantModal>
        <AddLaborCostRecordModal :visible.sync="addLaborCostRecordModalVisible"
            :isAssistant="getIsAssistant(selectedAddLaborUser.memberType)" :name="selectedAddLaborUser.name"
            :phone="selectedAddLaborUser.phone" :projectId="selectedAddLaborUser.projectId"
            :userId="selectedAddLaborUser.userId"> </AddLaborCostRecordModal>
        <AccountInfoModal :visible.sync="accountInfoModalVisible" :name="accountInfoUser.name"
            :phone="accountInfoUser.phone" :projectId="accountInfoUser.projectId"
            :userId="accountInfoUser.userId"></AccountInfoModal>
        <AssistantHistoryModal :visible.sync="assistantHistoryModalVisible" :userId="checkHistoryUser.userId" :name="checkHistoryUser.name">
        </AssistantHistoryModal>
    </div>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { computed, h, onMounted, ref } from 'vue-demi';
import { cancelAssistant, getProjectUserManager, kickOutUser } from '../../../api/projectUserManager';
//const tableHeight = document.documentElement.clientHeight - 230;
import { getProjectMemberTypes } from '../../../utils/enums';
import SetProjectAssistantModal from '../components/setProjectAssistantModal.vue';
import AddLaborCostRecordModal from '../components/addLaborCostRecordModal.vue';
import AccountInfoModal from '../components/accountInfoModal.vue';
import AssistantHistoryModal from '../components/assistantHistoryModal.vue';
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        width: 65,
        scopedSlots: { customRender: "index" }
    },
    {
        title: '姓名',
        dataIndex: 'name'
    },
    {
        title: '手机号',
        dataIndex: 'phone'
    },
    {
        title: '邮箱',
        dataIndex: 'email'
    },
    {
        title: '单位',
        dataIndex: 'orgName'
    },
    {
        title: '职务',
        dataIndex: 'postName'
    },
    {
        title: '项目助理经历',
        dataIndex: 'hasAssistantHistory',
        scopedSlots: { customRender: "hasAssistantHistory" }
    },
    {
        title: '角色',
        scopedSlots: { customRender: "memberType" }
    },
    {
        title: '操作',
        width: 300,
        fixed: "right",
        scopedSlots: { customRender: "operation" }
    }
]
export default {
    props: {},
    setup(props, context) {
        // 项目ID
        const projectId = ref(context.root.$route.query.id ? context.root.$route.query.id : null);
        // 成员列表
        const memberList = ref([]);
        // 获取列表
        const getUser = async () => {
            loading.value = true;
            const res = await getProjectUserManager({
                projectid: projectId.value
            });
            if (res.code === 200) {
                memberList.value = res.data;
            }
            loading.value = false;
        }
        // 列表加载
        const loading = ref(false);
        onMounted(async () => {
            if(title) {
                document.title = `${title} | 项目成员管理 | 科研诚信管理系统`
            }
            getUser();
        });
        // 是否为项目经理
        const getIsAssistant = (memberStr) => {
            return memberStr.toString().indexOf("2") !== -1;
        };
        // 是否位项目负责人
        const getIsInCharge = (memberStr) => {
            return memberStr.toString().indexOf("4") !== -1;
        }
        const getIsNomalMember = (memberStr) => {
            return memberStr.toString().indexOf("1") !== -1;
        }
        // 发放劳务费
        const getServiceChargeRecordUrl = computed(() => {
            const href = context.root.$router.resolve({
                name: "serviceChargeRecord",
                query: {
                    id: projectId.value,
                    title: title ? title : undefined
                }
            });
            return href.href;
        });
        /************************             设置项目助理 start           ************************/
        // 设置项目助理对话框
        const setAssistantModalVisible = ref(false);
        // 选中的用户
        const selectedAssistantUser = ref({});
        // 设置项目助理点击事件
        const handleSetAssistantButtonClick = (user) => {
            selectedAssistantUser.value = user;
            setAssistantModalVisible.value = true;
        }
        // 设置项目助理的回调
        const onSetAssistant = () => {
            getUser();
        }
        // 取消项目助理
        const handleCancelAssistant = (user) => {
            Modal.confirm({
                title: '提示',
                content: `是否确认取消项目助理职务`,
                //centered: true,
                onOk: async () => {
                    const res = await cancelAssistant({
                        projectid: user.projectId,
                        userid: user.userId
                    });
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        getUser();
                    }
                }
            })
        }
        /************************             设置项目助理 end             ************************/

        /************************               发放劳务费  start          ************************/
        // 发放劳务费对话框可见变量
        const addLaborCostRecordModalVisible = ref(false);
        // 正在发放劳务费的用户
        const selectedAddLaborUser = ref({
            memberType: ''
        });
        // 发放劳务费对话框点击事件
        const handleAddLaborCostClick = (user) => {
            selectedAddLaborUser.value = user;
            addLaborCostRecordModalVisible.value = true;
        }
        /************************               发放劳务费  end            ************************/
        /************************               账户信息 start             ************************/
        // 账户信息对话框可见变量
        const accountInfoModalVisible = ref(false);
        // 账户信息对话框选择对象
        const accountInfoUser = ref({});
        // 账户信息对话框选择
        const handleAccountInfoClick = (user) => {
            accountInfoUser.value = user;
            accountInfoModalVisible.value = true;
        }
        /************************               账户信息 end               ************************/
        /************************               查看助理经历 start               ************************/
        // 查看项目助理经历对话框可见变量
        const assistantHistoryModalVisible = ref(false);
        // 查看的用户
        const checkHistoryUser = ref({})
        // 查看项目助理按钮点击事件
        const handleCheckAssitantHistoryClick = (user) => {
            checkHistoryUser.value = user;
            assistantHistoryModalVisible.value = true;
        }
        /************************               查看助理经历 end                 ************************/
        /************************               踢出项目组 start                 ************************/
        const handleKickOut = (record) => {
            Modal.confirm({
                title: '提示',
                content: h('p', {  }, [ h('span', {}, '是否确认将成员'), h('span', { style: { color: '#1890ff' } }, `${record.name}`), h('span', {}, '移出项目？') ]),
                onOk: async () => {
                  const res = await kickOutUser({
                    ProjectId: projectId.value,
                    UserId: record.userId
                  });
                  if(res.code === 200 || res.code === 204) {
                    message.success(res.message);
                    getUser();
                  }
                },
            })
        }
        /************************               踢出项目组   end                 ************************/

        const title = context.root.$route.query.title ? context.root.$route.query.title : '';
        return {
            projectId,
            memberList,
            columns,
            //tableHeight,
            getProjectMemberTypes,
            getIsAssistant,
            getServiceChargeRecordUrl,
            setAssistantModalVisible,
            selectedAssistantUser,
            handleSetAssistantButtonClick,
            getIsInCharge,
            onSetAssistant,
            loading,
            handleCancelAssistant,
            addLaborCostRecordModalVisible,
            selectedAddLaborUser,
            handleAddLaborCostClick,
            accountInfoModalVisible,
            accountInfoUser,
            handleAccountInfoClick,
            assistantHistoryModalVisible,
            handleCheckAssitantHistoryClick,
            checkHistoryUser,
            handleKickOut,
            getIsNomalMember,
            title
        };
    },
    components: { SetProjectAssistantModal, AddLaborCostRecordModal, AccountInfoModal, AssistantHistoryModal }
}
</script>

<style lang="less" scoped>
.project-member-manage-ctn {
    background: #FFFFFF;
    min-height: calc(100vh - 100px);
    padding: 16px;
    color: rgba(0, 0, 0, 0.85);

    .project-member-manage-top {
        display: flex;
        padding-bottom: 16px;
        border-bottom: 1px solid #EBEBEB;

        .project-member-manage-title {
            width: 0px;
            flex-grow: 1;
            font-size: 18px;
            text-align: left;
            font-weight: bold;
        }

        .service-charge-btn>a {
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;

            &:hover {
                color: rgba(24, 144, 255, 1);
            }

            transition: .3s color ease;
        }
    }

    .table-ctn {
        min-height: calc(100vh - 180px);
        margin-top: 16px;

        /deep/ .ant-table-thead {
            background: #F0F5FF;
        }

        /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
            margin: 0px;
        }

        /deep/ .ant-table-row {
            td {
                padding: 7px;
            }
        }

        .operations {
            display: flex;

            a+a {
                margin-left: 24px;
            }
        }
    }
}
</style>