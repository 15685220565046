<template>
    <a-modal :width="800" title="项目助理经历" :visible.sync="visible" @cancel="handleCancel" :footer="null">
        <a-spin :spinning="loading" tip="正在加载中...">
            <div class="user-name">
                {{ name }}
            </div>
            <div class="assistant-historys">
                <div class="history" v-for="(history, index) in historyList" :key="index">
                    <div class="project-name">
                        <div class="history-label">
                            项目名称：
                        </div>
                        <div class="history-value">
                            {{ history.projectName }}
                        </div>
                    </div>
                    <div class="">
                        <a-row :gutter="16">
                            <a-col :span="8">
                                <div class="historty-item">
                                    <div class="history-label">
                                        任职时间：
                                    </div>
                                    <div class="history-value">
                                        {{ history.stareTime.split(' ')[0] }} - {{ history.endTime.split(' ')[0] }}
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="8">
                                <div class="historty-item">
                                    <div class="history-label">
                                        项目负责人：
                                    </div>
                                    <div class="history-value">
                                        {{ history.headName }}
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="8">
                                <div class="historty-item">
                                    <div class="history-label">
                                        负责人电话：
                                    </div>
                                    <div class="history-value">
                                        {{ history.headPhone }}
                                    </div>
                                </div>
                            </a-col>
                        </a-row>
                        <div class="historty-item">
                            <div class="history-label">
                                研究目的：
                            </div>
                            <div class="history-value">
                               {{ history.objective }}
                            </div>
                        </div>
                        <!-- <div class="objective">
                            研究目的：
                        </div> -->
                    </div>
                </div>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { watch, ref } from 'vue-demi';
import { getAssistantHistory } from '../../../api/projectUserManager';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        userId: {
            type: String | null | Number,
            required: true
        },
        name: {
            type: String | undefined,
            required: true
        }
    },
    setup(props, context) {
        const historyList = ref([]);
        const handleCancel = () => {
            context.emit('update:visible', false)
        }
        const loading = ref(false);
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                loading.value = true;
                const res = await getAssistantHistory({
                    userid: props.userId
                });
                if (res.code === 200) {
                    historyList.value = res.data;
                    loading.value = false;
                }
            }
        })
        return {
            historyList,
            handleCancel,
            loading
        }
    }
}
</script>

<style lang="less" scoped>
.user-name {
    font-size: 18px;
    font-weight: bold;
}

.assistant-historys {
    margin-top: 24px;
}

.history {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 24px;

    &+& {
        margin-top: 16px;
    }
}

.historty-item,
.project-name {
    display: flex;

    .history-label {
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
    }
}
</style>