// 项目组成员管理
import  request from "../utils/request";

// 查询项目组成员信息
export const getProjectUserManager = (params) => {
    return request({
        method: 'get',
        url: `/projectusermanager/getprojectusermanager`,
        params
    })
}

// 取消项目助理
export const removeAssistant = (params) => {
    return request({
        method:'post',
        url: `/projectusermanager/removeassistant`,
        params
    })
}

// 查询项目助理个人信息
export const getSetAssistantUserInfo = (params) => {
    return request({
        method: 'get',
        url: `/projectusermanager/getprogectassistant`,
        params
    })
}

// 设置项目助理
export const setAssistant = (data) => {
    return request({
        method: 'post',
        url: `/projectusermanager/setassistant`,
        data
    })
}

// 取消项目助理
export const cancelAssistant = (params) => {
    return request({
        method: 'post',
        url: `/projectusermanager/removeassistant`,
        params
    })
}

// 查询劳务费发放记录
export const getLaborCostHistory = (params) => {
    return request({
        method: 'get',
        url: `/projectusermanager/getlaborcosthistory`,
        params
    })
}

// 查询用户劳务费信息
export const getUserLaborCost = (params) => {
    return request({
        method: 'get',
        url: `/projectusermanager/getlaborcost`,
        params
    })
}

// 发放劳务费
export const addLaborCostRecord = (data) => {
    return request({
        method: 'post',
        url: '/projectusermanager/addlaborcostsrecord',
        data
    })
}

// 查询项目助理账户信息
export const getProjectAssistantAccount = (params) => {
    return request({
        method: 'get',
        url: `/projectusermanager/getprogectassistantaccount`,
        params
    })
}

// 修改账户信息
export const editLaborAccountInfo = (data) => {
    return request({
        method: 'post',
        url: `/projectusermanager/editlaborcoststandards`,
        data
    })
}

// 查询项目助理历史记录
export const getAssistantHistory = (params) => {
    return request({
        method: 'get',
        url: `/projectusermanager/getprogectassistanthistory`,
        params
    })
}

// 加入项目组
export const joinProject = (params) => {
    return request({
        method: 'post',
        url: `/projectusermanager/joinproduct`,
        params
    })
}

// 查询一条劳务费发放记录的凭证信息
export const getLaborCostTranservoucher = (params) => {
    return request({
        method: 'get',
        url: `/projectusermanager/getlaborcosttransfervoucher`,
        params
    })
}

// 移出成员
export const kickOutUser = (params) => {
    return request({
        method: 'post',
        url: `/projectusermanager/deleteProjectUser`,
        params
    })
}