<template>
    <a-modal :width="600" title="设置项目助理" :visible.sync="visible" @cancel="handleCancel">
        <a-spin :spinning="loading" tip="正在加载...">
            <a-form-model style="width: 80%;margin: 0 auto;" ref="formRef" :model="formData" :colon="true" :labelCol="{
                xs: { span: 24 },
                sm: { span: 5 },
            }" :wrapper-col="{ span: 19 }" :rules="rules">
                <a-form-model-item label="姓名">
                    <a-input :value="name" :disabled="true"></a-input>
                </a-form-model-item>
                <a-form-model-item label="手机">
                    <a-input :value="phone" :disabled="true"></a-input>
                </a-form-model-item>
                <a-form-model-item label="身份证号" prop="idNumber">
                    <a-input placeholder="请输入" v-model="formData.idNumber"></a-input>
                </a-form-model-item>
               <!--  <a-form-model-item label="担任时间" prop="timeRange">
                    <a-range-picker valueFormat="YYYY-MM-DD" :disabledDate="disabledDate" v-model="formData.timeRange" style="width: 100%;" />
                </a-form-model-item> -->
                <a-form-model-item label="银行卡号" prop="bankCard">
                     <a-input placeholder="请输入" v-model="formData.bankCard"></a-input>
                </a-form-model-item>
                <a-form-model-item label="开户银行" prop="bankName">
                    <a-input placeholder="请输入" v-model="formData.bankName"></a-input>
                </a-form-model-item>
                <a-form-model-item label="劳务费标准" prop="laborCostStandards">
                    <a-input-number style="width: calc(100% - 120px);" :min="0" v-model="formData.laborCostStandards" placeholder="请输入"></a-input-number>
                    <a-select style="width: 100px;margin-left: 16px;" v-model="formData.laborCostTypes">
                        <a-select-option v-for="(item,index) in laborCostTypes" :value="item.value" :key="index">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" :disabled="loading" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import dayjs from 'dayjs';
import moment from 'moment';
import { ref, watch } from 'vue-demi';
import { getSetAssistantUserInfo,getProjectAssistantAccount, setAssistant } from '../../../api/projectUserManager';
import { laborCostTypes } from '../../../utils/enums';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        name: {
            type: String | null,
            required: true
        },
        phone: {
            type: String | null | Number,
            required: true
        },
        projectId: {
            type: String | null | Number,
            required: true
        },
        userId: {
            type: String | null | Number,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false)
        }
        const handleConfirm = () => {
            formRef.value && formRef.value.validate( async (valid) => {
                if(valid) {
                    loading.value = true;
                    const res = await setAssistant({
                        name: props.name,
                        phone: props.phone,
                        projectId: props.projectId,
                        userId: props.userId,
                        idNumber: formData.value.idNumber,
                        bankName: formData.value.bankName,
                        bankCard: formData.value.bankCard,
                        laborCostStandards: formData.value.laborCostStandards,
                        laborCostTypes: formData.value.laborCostTypes,
                        startTime: formData.value.timeRange[0],
                        endTime: formData.value.timeRange[1]
                    });
                    if(res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit(`update:visible`,false);
                        context.emit('onSetAssistant')
                    }
                    loading.value = false;
                }
            });
        }
        // 表格数据
        const formData = ref({
            idNumber: null, // 身份证号
            bankCard: null, // 银行卡号
            laborCostStandards: undefined,  // 劳务费用标准
            timeRange: [dayjs().format('YYYY-MM-DD'), dayjs().add(2,'year')],  // 担任事件
            laborCostTypes: 1,   // 劳务费用类型标准
            bankName: undefined   // 开户银行
        })
        // 表格实例
        const formRef = ref(null);
        // 加载中
        const loading = ref(false);
        // 校验规则
        const rules = ref({
            idNumber: [{
                required: true,
                validator: (_rule, value, callback) => {
                    if(!value || !value.toString().trim()) {
                        callback(new Error('请输入'))
                    }
                    else {
                        if(value.toString().length !== 18) {
                            callback(new Error('身份证号码应为18位数组'))
                        }
                    }
                    callback();
                }
            }],
            bankCard: [{
                required: true,
                message: '请输入银行卡号'
            }],
            laborCostStandards: [{
                required: true,
                message: '请输入'
            }],
            bankName: [{
                required: true,
                message: '请选择'
            }],
            timeRange: [{
                required: true,
                message: '请输入'
            }]
        })
        // 项目时间范围
        const projectRange = ref([ moment(), moment().add(2, 'year') ]);
        // 不可选择时间
        const disabledDate = (disabledDate) => {
            return disabledDate && (disabledDate < projectRange.value[0] || disabledDate > projectRange.value[1]);
        }

        // 初始化
        watch(() => props.visible, async (newVal) => {
            if(newVal) {
                formRef.value && formRef.value.resetFields();
                loading.value = true;
                formData.value = {
                    idNumber: null, // 身份证号
                    bankCard: null, // 银行卡号
                    laborCostStandards: undefined,  // 劳务费用标准
                    timeRange: [],  // 担任事件
                    laborCostTypes: 1,   // 劳务费用类型标准
                    bankName: undefined
                }
                const res = await getSetAssistantUserInfo({
                    projectid: props.projectId,
                    userid: props.userId
                });
                if(res.code === 200) {
                    formData.value.timeRange = [res.data.projectStartTime.split(` `)[0], res.data.projectEndTime.split(' ')[0] ]
                    projectRange.value = [moment(res.data.projectStartTime), moment(res.data.projectEndTime)]
                    loading.value = false;
                }
                /* const Ares = await getProjectAssistantAccount({
                    projectid: props.projectId,
                    userid: props.userId
                });
                if(Ares.code === 200) {
                    formData.value.idNumber = Ares.data.idNumber;
                    formData.value.bankCard = Ares.data.bankCard;
                    formData.value.bankId = Ares.data.bankId;
                    formData.value.laborCostTypes = Ares.data.laborCostTypes;
                    formData.value.laborCostStandards = Ares.data.laborCostStandards;
                    loading.value = false;
                } */
            }
        })
        return {
            handleCancel,
            handleConfirm,
            formData,
            formRef,
            rules,
            laborCostTypes,
            loading,
            disabledDate
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>