<template>
    <a-modal :width="600" title="添加劳务费" :visible.sync="visible" @cancel="handleCancel">
        <a-spin :spinning="loading" tip="正在加载...">
            <a-form-model style="width: 80%;margin: 0 auto;" ref="formRef" :model="formData" :colon="true" :labelCol="{
                xs: { span: 24 },
                sm: { span: 5 },
            }" :wrapper-col="{ span: 19 }" :rules="rules">
                <a-form-model-item label="姓名">
                    <a-input :value="name" :disabled="true"></a-input>
                </a-form-model-item>
                <a-form-model-item label="手机">
                    <a-input :value="phone" :disabled="true"></a-input>
                </a-form-model-item>
                <a-form-model-item v-if="isAssistant" label="劳务费标准">
                    <a-input :value="laborCostStand" :disabled="true"></a-input>
                </a-form-model-item>
                <a-form-model-item label="发放时间" prop="recordTime">
                    <a-date-picker style="width: 100%;" v-model="formData.recordTime" placeholder="请选择" valueFormat="YYYY-MM-DD"></a-date-picker>
                </a-form-model-item>
                <a-form-model-item label="发放金额" prop="recordMoney">
                    <a-input-number style="width: 100%;" v-model="formData.recordMoney" :min="0" placeholder="请输入单位（元）"></a-input-number>
                </a-form-model-item>
                <a-form-model-item label="上传凭证" prop="transferVouchers">
                    <TransferVoucherUpload v-if="visible" :fileList.sync="formData.transferVouchers"></TransferVoucherUpload>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" :disabled="loading" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { computed, ref, watch } from 'vue-demi';
import { getProjectAssistantAccount, addLaborCostRecord } from '../../../api/projectUserManager';
import { laborCostTypes } from '../../../utils/enums';
import TransferVoucherUpload from './transferVoucherUpload.vue';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        name: {
            type: String | null,
            required: true
        },
        phone: {
            type: String | null | Number,
            required: true
        },
        projectId: {
            type: String | null | Number,
            required: true
        },
        userId: {
            type: String | null | Number,
            required: true
        },
        isAssistant: {
            type: Boolean,
            required: true,
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        const handleConfirm = () => {
            formRef.value && formRef.value.validate(async (valid) => {
                if (valid) {
                    loading.value = true;
                    const res = await addLaborCostRecord({
                        recordTime: formData.value.recordTime,
                        recordMoney: formData.value.recordMoney,
                        transferVoucherone: formData.value.transferVouchers.length > 0 ? formData.value.transferVouchers[0].thumbUrl : "",
                        transferVouchertwo: formData.value.transferVouchers.length > 1 ? formData.value.transferVouchers[1].thumbUrl : "",
                        transferVoucherthere: formData.value.transferVouchers.length > 2 ? formData.value.transferVouchers[2].thumbUrl : "",
                        transferVoucherfour: formData.value.transferVouchers.length > 3 ? formData.value.transferVouchers[3].thumbUrl : "",
                        projectId: props.projectId,
                        payeeId: props.userId
                    });
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit("update:visible", false);
                    }
                }
            });
        };
        // 表格数据
        const formData = ref({
            recordTime: null,
            recordMoney: null,
            transferVouchers: [] // 上传凭证
        });
        // 表格实例
        const formRef = ref(null);
        // 加载中
        const loading = ref(false);
        // 校验规则
        const rules = ref({
            recordTime: [{
                    required: true,
                    message: "请选择"
                }],
            recordMoney: [{
                    required: true,
                    message: "请输入"
                }]
        });
        // 助理账户信息
        const accountInfo = ref({});
        // 初始化
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                formRef.value && formRef.value.resetFields();
                loading.value = true;
                formData.value = {
                    recordTime: null,
                    recordMoney: null,
                    transferVouchers: []
                };
                if (props.isAssistant) {
                    loading.value = true;
                    const res = await getProjectAssistantAccount({
                        userid: props.userId,
                        projectId: props.projectId
                    });
                    if (res.code === 200) {
                        accountInfo.value = res.data;
                        loading.value = false;
                    }
                }
            }
        });
        // 劳务费
        const laborCostStand = computed(() => {
            if (!accountInfo.value.laborCostTypes) {
                return "";
            }
            else {
                const laborType = laborCostTypes.filter(t => t.value === accountInfo.value.laborCostTypes)
                return `${accountInfo.value.laborCostStandards}${laborType[0].label}`;
            }
        });
        return {
            handleCancel,
            handleConfirm,
            formData,
            formRef,
            rules,
            loading,
            accountInfo,
            laborCostTypes,
            laborCostStand
        };
    },
    components: { TransferVoucherUpload }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>