<template>
    <a-modal v-if="visible" :width="600" title="账户信息" :visible.sync="visible" @cancel="handleCancel">
        <a-spin :spinning="loading" tip="正在加载...">
            <a-form-model v-if="visible" style="width: 80%;margin: 0 auto;" ref="formRef" :model="formData"
                :colon="true" :labelCol="{
                    xs: { span: 24 },
                    sm: { span: 5 },
                }" :wrapper-col="{ span: 19 }" :rules="rules">
                <a-form-model-item label="身份证号" prop="idNumber">
                    <a-input v-if="isEditId" placeholder="请输入" v-model="formData.idNumber">
                    </a-input>
                    <div v-else style="display: flex;">
                        <a-input placeholder="请输入" disabled v-model="initId"></a-input>
                        <a-button type="link" @click="isEditId = true"> 修改 </a-button>
                    </div>
                </a-form-model-item>
                <a-form-model-item label="银行卡号" prop="bankCard">
                    <a-input v-if="isEditBankCark" placeholder="请输入" v-model="formData.bankCard"></a-input>
                    <div v-else style="display: flex;">
                        <a-input placeholder="请输入" disabled v-model="initBankCard"></a-input>
                        <a-button type="link" @click="isEditBankCark = true"> 修改 </a-button>
                    </div>
                </a-form-model-item>
                <a-form-model-item label="开户银行" prop="bankId">
                    <a-input placeholder="请输入" v-model="formData.bankName"></a-input>
                </a-form-model-item>
                <a-form-model-item label="劳务费标准" prop="laborCostStandards">
                    <a-input-number style="width: calc(100% - 120px);" :min="0" v-model="formData.laborCostStandards"
                        placeholder="请输入"></a-input-number>
                    <a-select style="width: 100px;margin-left: 16px;" v-model="formData.laborCostTypes">
                        <a-select-option v-for="(item, index) in laborCostTypes" :value="item.value" :key="index">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" :disabled="loading || isSame" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { computed, ref, watch } from 'vue-demi';
import { getProjectAssistantAccount, editLaborAccountInfo } from '../../../api/projectUserManager';
import { laborCostTypes } from '../../../utils/enums';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        name: {
            type: String | null,
            required: true
        },
        phone: {
            type: String | null | Number,
            required: true
        },
        projectId: {
            type: String | null | Number,
            required: true
        },
        userId: {
            type: String | null | Number,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false)
        }
        const handleConfirm = () => {
            console.log(formData.value)
            formRef.value && formRef.value.validate(async (valid) => {
                if (valid) {
                    loading.value = true;
                    const res = await editLaborAccountInfo({
                        projectId: props.projectId,
                        userId: props.userId,
                        idNumber: formData.value.idNumber,
                        bankCard: formData.value.bankCard,
                        laborCostStandards: formData.value.laborCostStandards,
                        laborCostTypes: formData.value.laborCostTypes,
                        bankName: formData.value.bankName
                    });
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit(`update:visible`, false);
                    }
                    loading.value = false;
                }
            });
        }
        // 表格数据
        const formData = ref({
            idNumber: null, // 身份证号
            bankCard: null, // 银行卡号
            laborCostStandards: undefined,  // 劳务费用标准
            laborCostTypes: 1,   // 劳务费用类型标准
            bankName: ``,  // 开户银行
        });
        // 初始化身份证号
        const initId = ref(undefined);
        // 初始化银行卡号
        const initBankCard = ref(undefined);
        // 是否编辑id
        const isEditId = ref(false);
        // 是否编辑银行卡
        const isEditBankCark = ref(false);
        // 表格实例
        const formRef = ref(null);
        // 加载中
        const loading = ref(false);
        // 校验规则
        const rules = ref({
            idNumber: [{
                required: true,
                validator: (_rule, value, callback) => {
                    if (!isEditId.value) {
                        callback();
                    }
                    if (!value || !value.toString().trim()) {
                        callback(new Error('请输入'))
                    }
                    else {
                        if (value.toString().length !== 18) {
                            callback(new Error('身份证号码应为18位数组'))
                        }
                    }
                    callback();
                }
            }],
            bankCard: [{
                required: true,
                whitespace: true,
                validator: (_rule, value, callback) => {
                    if(!isEditBankCark.value)  {
                        callback();
                    }
                    if(!value || !value.toString().trim()) {
                        callback(new Error('请输入'))
                    }
                    else {
                        callback();
                    }
                },
                message: '请输入银行卡号'
            }],
            laborCostStandards: [{
                required: true,
                message: '请输入'
            }],
            bankName: [{
                required: true,
                message: '请输入'
            }]
        })
        // 初始化数据
        const initData = ref({
        })

        // 是否更改
        const isSame = computed(() => {
            return JSON.stringify(initData.value) === JSON.stringify(formData.value);
        })
        // 初始化
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                loading.value = true;
                formData.value = {
                    idNumber: undefined, // 身份证号
                    bankCard: undefined, // 银行卡号
                    laborCostStandards: undefined,  // 劳务费用标准
                    laborCostTypes: 1,   // 劳务费用类型标准
                    bankName: undefined
                }
                isEditBankCark.value = false;
                isEditId.value = false;
                initId.value = undefined;
                initBankCard.value = undefined;
                formRef.value && formRef.value.resetFields();

                const res = await getProjectAssistantAccount({
                    projectid: props.projectId,
                    userid: props.userId
                });
                if (res.code === 200) {
                    /* formData.value.idNumber = res.data.idNumber;
                    formData.value.bankCard = res.data.bankCard; */
                    initId.value = res.data.idNumber;
                    initBankCard.value = res.data.bankCard;
                    formData.value.bankName = res.data.bankName;
                    formData.value.laborCostTypes = res.data.laborCostTypes;
                    formData.value.laborCostStandards = res.data.laborCostStandards;
                    initData.value = JSON.parse(JSON.stringify(formData.value));
                    loading.value = false;
                }
            }
        })
        return {
            handleCancel,
            handleConfirm,
            formData,
            formRef,
            rules,
            laborCostTypes,
            loading,
            isSame,
            initId,
            initBankCard,
            isEditId,
            isEditBankCark
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>